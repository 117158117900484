<template>
	<div>
		<div class="inlineBlock mb74-74-40">
			<div class="inlineBlock__left">
				<p class="h2 animationBlockFadeUp" v-html="content.title" v-if="content.title"></p>
			</div>
			<div class="inlineBlock__right">
				<p class="color-gray animationBlockFadeUp fs20-2" v-html="content.desc" v-if="content.desc"></p>
			</div>
		</div>
		<div class="inlineBlock type2">
			<div class="inlineBlock__left">
				<div class="employeesSlider__container">
					<VueSlickCarousel class="employeesSlider animationBlockFadeUp" ref="slider" v-bind="slickSettings"  @beforeChange="beforeChange">
						<div class="employeesSlider__item" v-for="(slide, index) in content.items">
							<div v-if="slide">
								<div class="employeesSlider__img" v-lazy:background-image="slide.img"></div>
								<div class="employeesSlider__content">
									<p class="employeesSlider--name" v-html="slide.name" v-if="slide.name"></p>
									<p class="employeesSlider--description" v-html="slide.description" v-if="slide.description"></p>
								</div>
							</div>
						</div>
						<template #customPaging="page">
							<div class="dot"></div>
						</template>
					</VueSlickCarousel>
					<div class="employeesSlider__arrows customArrows animationBlockFadeUp">
						<a class="customArrows--prev" @click="prevSlide"></a>
						<a class="customArrows--next" @click="nextSlide"></a>
					</div>
				</div>
			</div>
			<div class="inlineBlock__right hide600">
				<div class="employeesItems">
					<div :class="[{'active': index == activeElement}, 'employeesItems__item', 'animationBlockFadeUp']" v-for="(item, index) in content.items" v-if="item" @click="employeeClick(index)" v-lazy:background-image="item.img">
						<div class="employeesItems__content">
							<p class="employeesItems--description" v-html="item.description" v-if="item.description"></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/new_sass/Employees'
</style>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

	export default {
		props: ['content'],
		data: () => ({
			slickSettings: {
				dots: false,
				arrows: true,
				infinite: true
			},
			activeElement: 0
		}),
		mounted(){
		},
		methods: {
			prevSlide: function(){
				this.$refs["slider"].prev();
			},
			nextSlide: function(){
				this.$refs["slider"].next();
			},
			beforeChange: function(oldSlideIndex, newSlideIndex){
				this.activeElement = newSlideIndex;
			},
			employeeClick: function(index){
				this.$refs["slider"].goTo(index);
			}
		},
		components: {
			VueSlickCarousel
		}
	}
</script>